var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v("1. " + _vm._s(_vm.$t("preference.wasiat")))]), _vm.preference.allow_wasiat == 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("yes")) + " ")]) : _vm._e(), _vm.preference.allow_wasiat != 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("no")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v("2. " + _vm._s(_vm.$t("preference.hibah")))])]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v("3. " + _vm._s(_vm.$t("preference.hibah-corporate")))]), _vm.preference.allow_hibah == 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("yes")) + " ")]) : _vm._e(), _vm.preference.allow_hibah != 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("no")) + " ")]) : _vm._e()])]), _vm.preference.allow_hibah == 1 ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 mb-3"
  }, [_c('hr'), _c('h6', [_vm._v(_vm._s(_vm.$t("preference.corporate-trust")))])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("is-add-prop-allowed")) + "?")]), _vm.preference.hibah_allow_moveable_property == 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("yes")) + " ")]) : _vm._e(), _vm.preference.hibah_allow_moveable_property != 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("no")) + " ")]) : _vm._e()]), _vm.organizationType == 'Awaris' ? _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.add-immovprop")))]), _vm.preference.hibah_allow_immoveable_property == 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("yes")) + " ")]) : _vm._e(), _vm.preference.hibah_allow_immoveable_property != 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("no")) + " ")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.movprop")))]), _vm.preference.hibah_single_moveable_property == 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("yes")) + " ")]) : _vm._e(), _vm.preference.hibah_single_moveable_property != 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("no")) + " ")]) : _vm._e()]), _vm.organizationType == 'Awaris' ? _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.immovprop")))]), _vm.preference.hibah_single_immoveable_property == 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("yes")) + " ")]) : _vm._e(), _vm.preference.hibah_single_immoveable_property != 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("no")) + " ")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("is-add-guardian-allowed")) + "?")]), _vm.preference.hibah_single_caretaker == 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("yes")) + " ")]) : _vm._e(), _vm.preference.hibah_single_caretaker != 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("no")) + " ")]) : _vm._e()]), _vm.organizationType == 'Awaris' ? _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.heir")))]), _vm.preference.hibah_single_heir == 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("yes")) + " ")]) : _vm._e(), _vm.preference.hibah_single_heir != 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("no")) + " ")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("is-add-beneficiaries-allowed")) + "?")]), _vm.preference.hibah_single_receiver == 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("yes")) + " ")]) : _vm._e(), _vm.preference.hibah_single_receiver != 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("no")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.saham-type")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.preference.corporate_saham_account_type) + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("preference.trust-property")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.preference.hibah_fixed_saham_type) + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("accept-payments")))]), _vm.preference.allow_payment == 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("yes")) + " ")]) : _vm._e(), _vm.preference.allow_payment == 2 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("no")) + " ")]) : _vm._e()])]) : _vm._e(), _c('hr'), _c('div', {
    staticClass: "text-right"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'organization.preference.edit',
        params: {
          organization_id: _vm.organizationId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("update")))])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }